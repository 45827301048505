<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("account.generationDetailsTitle", { generationId }) }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn small color="primary" class="mb-4" @click="download">
          {{ $t("account.exportData") }}
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <!-- Data loader -->
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>

      <div v-if="totalCount > 0">
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
        >
          <template v-slot:header>
            <div class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0">
              <v-row class="pa-3 pl-4 align-center grey--text">
                <v-col
                  sm="2"
                  md="2"
                  lg="2"
                  class="text-center caption align-self-end"
                >
                  {{ $t("account.attribute.fullname") }}
                </v-col>
                <v-col
                  sm="3"
                  md="1"
                  lg="1"
                  class="d-flex justify-space-around text-center caption align-self-end"
                >
                  {{ $t("account.attribute.gender") }}/{{
                    $t("account.attribute.language")
                  }}
                </v-col>
                <v-col sm="3" md="2" lg="1" class="caption align-self-end">
                  {{ $t("account.attribute.birthday") }}
                </v-col>
                <v-col
                  sm="2"
                  md="2"
                  lg="1"
                  class="text-center caption align-self-end"
                >
                  {{ $t("account.attribute.city") }}
                </v-col>
                <v-col sm="4" md="3" lg="2" class="caption align-self-end">
                  {{ $t("account.attribute.about") }}
                </v-col>
                <v-col sm="4" md="2" lg="1" class="caption align-self-end">
                  {{ $t("account.attribute.school") }}
                </v-col>
                <v-col sm="4" md="4" lg="2" class="caption align-self-end">
                  {{ $t("account.attribute.university") }}
                </v-col>
                <v-col sm="4" md="8" lg="2" class="caption align-self-end">
                  {{ $t("account.attribute.company") }}
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <account-generation-card
              v-for="(item, i) in props.items"
              :key="i"
              :accountData="item"
            ></account-generation-card>
          </template>
        </v-data-iterator>

        <div class="text-right">
          <span class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
      </div>
    </div>

    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { FETCH_ACCOUNT_GENERATION_DETAILS } from "@/store/actions/account-generation-details";
import AccountGenerationCard from "@/views/account-generation-details/AccountGenerationCard";
import downloadFile from "@/utils/file";

export default {
  name: "AccountGenerationDetailsCard",
  computed: {
    ...mapGetters("account/generationDetails", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "pagination",
    ]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
  },
  components: { AccountGenerationCard },
  props: {
    generationId: { type: String, required: true },
  },
  watch: {
    pagination: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        if (this.pagination.page > 1) {
          query.page = this.pagination.page;
        } else {
          delete query.page;
        }
        this.$router
          .push({
            name: this.currentRouteName,
            query,
          })
          .catch(() => {});
        this.loadItems();
      },
      deep: true,
    },
    generationId: function () {
      this.loadItems();
    },
  },
  data: function () {
    return {
      dataLoading: false,
      currentRouteName: this.$route.name,
    };
  },
  methods: {
    loadItems() {
      this.dataLoading = true;
      const { generationId: id } = this;
      this.$store
        .dispatch(
          "account/generationDetails/" + FETCH_ACCOUNT_GENERATION_DETAILS,
          { id }
        )
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        );
    },
    download() {
      downloadFile({
        url: `/data-generation/${this.generationId}/download`,
        filename: `account_data_dataset-${this.generationId}.jl`,
      }).catch((err) => console.log("Err", err));
    },
  },
  mounted() {
    let query = _.merge({}, this.$route.query);
    query.page = 1;
    this.pagination.page = 1;
    this.$router
      .push({
        name: this.currentRouteName,
        query,
      })
      .catch(() => {});
    this.loadItems();
  },
};
</script>
