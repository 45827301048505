<template>
  <v-sheet outlined class="mt-1">
    <v-row class="pa-3 pl-4 align-center">
      <v-col sm="2" md="2" lg="2" class="text-center">
        <span class="caption">{{ name }}</span>
      </v-col>
      <v-col
        sm="3"
        md="1"
        lg="1"
        class="d-flex justify-space-around text-center"
      >
        <v-icon>
          mdi-gender-{{ getSymbolByGender({ gender: accountData.gender }) }}
        </v-icon>
        <flag :iso="getAbrByLanguage({ language: accountData.lang })" />
      </v-col>
      <v-col sm="3" md="2" lg="1">
        <span class="caption">{{ accountData.bdate }}</span>
      </v-col>
      <v-col sm="2" md="2" lg="1" class="text-center">
        <span class="caption">{{ accountData.city || "-" }}</span>
      </v-col>
      <v-col sm="4" md="3" lg="2">
        <span class="caption">{{ accountData.about || "-" }}</span>
      </v-col>
      <v-col sm="4" md="2" lg="1">
        <span class="caption">{{ accountData.school || "-" }}</span>
      </v-col>
      <v-col sm="4" md="4" lg="2">
        <span class="caption">{{ accountData.universityName || "-" }}</span>
      </v-col>
      <v-col sm="4" md="8" lg="2">
        <span class="caption">{{ accountData.careerCompany || "-" }}</span>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountGenerationCard",
  props: {
    accountData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("account/donor", ["getSymbolByGender", "getAbrByLanguage"]),
    ...mapGetters("account/generationDetails", ["getAbrByLanguage"]),
    cityName: function () {
      return this.accountData.region ? this.accountData.region.title : "-";
    },
    name: function () {
      const parts = [];
      if (this.accountData.firstName) {
        parts.push(this.accountData.firstName);
      }

      if (this.accountData.lastName) {
        parts.push(this.accountData.lastName);
      }

      return parts.length ? parts.join(" ") : "-";
    },
  },
};
</script>
